<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Laporan Komisi Pegawai <b>Klinik IONA</b>
      </div>
    </b-alert>

    <div class="row">

      <div class="col-12">
        <Card>
          <template v-slot:body>
            <Table />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import VueHtml2pdf from "vue-html2pdf"
import Table from '@/component/reports/EmployeeCommisionReportTable.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Table,
    VueHtml2pdf
  },

  data() {
    return {

    }
  },

  methods: {



  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan", route: "" },
      { title: "Laporan Komisi Pegawai" },
    ])    
  },

}
</script>

<style>
</style>